import moment from "moment";
import React, { useCallback } from "react";
import colors from "src/assets/variables.scss";
import { Box, NoInfoGames } from "src/components";
import { useStore } from "src/store";
import { GameEvent } from "src/types";
import * as V from "victory";
import "./GraphLineBar.scss";
interface GraphLineBarProps {
  timePeriod: string;
  children?: React.ReactNode;
}

type Graph = { x: number; y: number }[];

const baseGraphNumber = [12, 9, 6, 3, 0];

export const GraphLineBar = (props: GraphLineBarProps): JSX.Element => {
  const { timePeriod } = props;
  
  const today = moment().dayOfYear();
  const [reports] = useStore((state) => [state.reports]);
  const [user] = useStore((state) => [state.user]);
  const [graphLine, setGraphLine] = React.useState<Graph>([]);
  const [graphBar, setGraphBar] = React.useState<Graph>([]);
  const [eventPeriod, setEventPeriod] = React.useState<number[]>([]);
  const [timePlayedTicks, setTimePlayedTicks] =
    React.useState<number[]>(baseGraphNumber);
  const [starsCollectedTicks, setStarsCollectedTicks] =
    React.useState<number[]>(baseGraphNumber);
  const widthOfTheBar = 38;

  const daysInPreviousYear = moment().subtract(1, "year").isLeapYear()
    ? 366
    : 365;

  const sortEvents = (events: GameEvent[]) => {
    const sortedEvents = events.sort(
      (a, b) => moment(a.eventDt).valueOf() - moment(b.eventDt).valueOf()
    );
    return sortedEvents;
  };

  const groupByDays = useCallback((events: GameEvent[]) => {
    const graphData: Graph = [];

    for (const event of events) {
      const weekDay = moment(event.eventDt).dayOfYear();
      const firstDayofEventsMonth = moment(event.eventDt).startOf("month");

      let axisDay = weekDay;

      if (timePeriod === "4-weeks" && user.id) {
        const daysDifference = today - weekDay;
        axisDay =
          daysDifference % 4 !== 2
            ? weekDay - (2 - (daysDifference % 4))
            : weekDay;
      }

      if (timePeriod === "6-months" && user.id) {
        axisDay =
          moment(event.eventDt).year() === moment().year()
            ? firstDayofEventsMonth.dayOfYear()
            : -(daysInPreviousYear - firstDayofEventsMonth.dayOfYear());
      }

      const indexGraphDay = graphData.findIndex(
        (graphDay) => graphDay.x === axisDay
      );

      if (indexGraphDay >= 0) {
        graphData[indexGraphDay].y += 1;
      } else {
        graphData.push({ x: axisDay, y: 1 });
      }
    }
    // ? INFO: add proportion stars list between the biggest number and zero

    const maxValue = Math.max(...graphData.map((o) => o.y));

    const proportionTime = maxValue * 0.25;

    let currentValue = maxValue + proportionTime;

    const tickValues =
      maxValue > 12
        ? Array.from({ length: 4 }, () => {
            currentValue -= proportionTime;
            return Number(currentValue.toFixed());
          })
        : baseGraphNumber;

    tickValues.push(0);

    setStarsCollectedTicks(tickValues);

    return graphData;
  }, [daysInPreviousYear, timePeriod, today, user.id]);

  const groupByMinutes = useCallback((events: GameEvent[]) => {
    const graphData: Graph = [];

    for (const event of events) {
      const firstDayofEventsMonth = moment(event.eventDt).startOf("month");
      const weekDay = moment(event.eventDt).dayOfYear();
      let axisDay = weekDay;

      if (timePeriod === "4-weeks" && user.id) {
        const daysDifference = today - weekDay;
        axisDay =
          daysDifference % 4 !== 2
            ? weekDay - (2 - (daysDifference % 4))
            : weekDay;
      }

      if (timePeriod === "6-months" && user.id) {
        axisDay =
          moment(event.eventDt).year() === moment().year()
            ? firstDayofEventsMonth.dayOfYear()
            : -(daysInPreviousYear - firstDayofEventsMonth.dayOfYear());
      }

      const indexGraphDay = graphData.findIndex(
        (graphDay) => graphDay.x === axisDay
      );

      const ms = moment(event.data.endDt).diff(moment(event.data.startDt));
      const duration = moment.duration(ms);
      const minutes = duration.asMinutes();

      if (indexGraphDay >= 0) {
        graphData[indexGraphDay].y += minutes;
      } else {
        graphData.push({ x: axisDay, y: minutes });
      }
    }

    const maxValue = Math.max(...graphData.map((o) => o.y));

    // ? INFO: add proportion time list between the biggest hour and zero

    const initialTime = Number(
      (maxValue < 100
        ? maxValue
        : (Math.round(maxValue / 100) + 1) * 100
      ).toFixed()
    );

    const proportionTime = initialTime * 0.25;

    let currentTime = initialTime;

    const tickValues = Array.from({ length: 4 }, () => {
      if (initialTime === 0) return 0;
      currentTime -= proportionTime;
      return Number(currentTime.toFixed());
    });

    //? INFO: add the max value in initial y position graph

    tickValues.unshift(initialTime);
    setTimePlayedTicks(tickValues);

    return graphData;
  }, [daysInPreviousYear, timePeriod, user.id, today]);

  const getEventPeriod = React.useCallback(() => {
    const timeStampX: { [key: string]: number } = {
      "7-days": 1,
      "4-weeks": 4,
    };

    const events = [...reports.starReceived, ...reports.timePlayed];
    const sortedEvents = sortEvents(events);
    const lastDay = sortedEvents[sortedEvents.length - 1];

    let lastSevenDays: number[] = [];
    let dayOfYearLastActivity = !user.id
      ? moment(lastDay.eventDt).dayOfYear()
      : timePeriod !== "6-months"
      ? today - timeStampX[timePeriod]
      : moment().startOf("month").dayOfYear();

    Array.from({ length: 7 }, (_, index) => {
      lastSevenDays.push(dayOfYearLastActivity);
      const firstDayLastMonth = moment()
        .subtract(index + 1, "month")
        .startOf("month");

      if (!user.id) return (dayOfYearLastActivity -= 1);
      if (timePeriod !== "6-months")
        return (dayOfYearLastActivity -= timeStampX[timePeriod]);

      if (moment().year() !== firstDayLastMonth.year()) {
        return (dayOfYearLastActivity = -(
          daysInPreviousYear - firstDayLastMonth.dayOfYear()
        ));
      }
      return (dayOfYearLastActivity = firstDayLastMonth.dayOfYear());
    });
    lastSevenDays.reverse();

    setEventPeriod(lastSevenDays);
  }, [reports.starReceived, reports.timePlayed, timePeriod, today, user.id, daysInPreviousYear]);

  const computeStarEvents = React.useCallback(() => {
    const events = sortEvents(reports.starReceived);
    const grouped = groupByDays(events);
    setGraphBar(grouped);
  }, [reports.starReceived, groupByDays]);

  const computeTimePlayedEvents = React.useCallback(() => {
    const events = sortEvents(reports.timePlayed);
    const grouped = groupByMinutes(events);

    setGraphLine(grouped);
  }, [reports.timePlayed, groupByMinutes]);

  const getDayFormat = (day: number) => {
    const formatDisplayDay =
      !user || timePeriod === "7-days"
        ? "ddd"
        : timePeriod === "6-months"
        ? "MMM"
        : "DD/MM";
    const diff = today - day;

    return moment().subtract({ days: diff }).format(formatDisplayDay);
  };

  React.useEffect(() => {
    if (reports.starReceived.length || reports.timePlayed.length)
      getEventPeriod();
  }, [getEventPeriod, reports.starReceived.length, reports.timePlayed.length]);

  React.useEffect(() => {
    if (reports.starReceived.length) computeStarEvents();
  }, [computeStarEvents, reports.starReceived]);

  React.useEffect(() => {
    if (reports.timePlayed.length) computeTimePlayedEvents();
  }, [computeTimePlayedEvents, reports.timePlayed.length]);

  return (
    <Box className="graph-line-bar p-0 pb-4 flex flex-col justify-between">
      <div className="relative">
        <div className="flex justify-between p-4 pb-0 -mb-8  gap-2 z-20">
          <div className="type-box py-1 p-2 flex gap-2 items-center">
            <img
              className="w-8"
              src={`${process.env.REACT_APP_PAGE_URL}icons/star.png`}
              alt=""
            />
            <strong>Stars Earned</strong>
          </div>

          <div className="type-box py-1 p-2 flex gap-2 items-center">
            <strong>Minutes Played</strong>
            <img
              className="w-8"
              src={`${process.env.REACT_APP_PAGE_URL}icons/clock.svg`}
              alt=""
            />
          </div>
        </div>

        {!graphBar.length && !graphLine.length && (
          <div className="absolute w-full h-full flex items-center justify-center">
            <NoInfoGames />
          </div>
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <V.VictoryChart domainPadding={{ x: 30, y: 0 }} height={240}>
            <V.VictoryAxis
              tickValues={eventPeriod}
              tickFormat={eventPeriod.map((day) => getDayFormat(day))}
              style={{
                axis: {
                  opacity: 0,
                },
                tickLabels: {
                  opacity: 0,
                },
              }}
            />
            <V.VictoryAxis
              dependentAxis
              offsetX={435}
              tickValues={timePlayedTicks}
              style={{
                axis: { strokeWidth: 0 },
                tickLabels: {
                  fontWeight: 600,
                  fontFamily: "Gotham",
                },
              }}
            />
            <V.VictoryGroup data={graphLine}>
              <V.VictoryLine
                interpolation="monotoneX"
                style={{
                  data: { stroke: colors.themeDarkBlue, strokeWidth: 3 },
                }}
              />
              <V.VictoryScatter
                data={graphLine.map((point) => ({
                  ...point,
                  x: point.x - widthOfTheBar / 2,
                }))}
                size={5}
                style={{
                  data: {
                    fill: colors.themeDarkBlue,
                  },
                }}
              />
            </V.VictoryGroup>
          </V.VictoryChart>
        </div>

        <V.VictoryChart domainPadding={{ x: 30, y: 0 }} height={240}>
          <svg>
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="0%" x2="50%" y2="100%">
                <stop stopColor={colors.themeLightOrange} />
                <stop offset="0.279167" stopColor={colors.themeLightYellow} />
                <stop offset="0.529167" stopColor={colors.themeYellow} />
                <stop offset="1" stopColor={colors.themeOrange} />
              </linearGradient>
            </defs>
          </svg>
          <V.VictoryAxis
            tickValues={eventPeriod}
            tickFormat={eventPeriod.map((day) => getDayFormat(day))}
            style={{
              axis: {
                strokeWidth: 3,
                stroke: colors.themeDarkBlue,
              },
              tickLabels: {
                fontFamily: "Gotham",
                fontSize: 12,
                fontWeight: 600,
              },
            }}
          />
          <V.VictoryAxis
            dependentAxis
            offsetX={50}
            tickFormat={starsCollectedTicks}
            style={{
              axis: { stroke: "transparent" },
              tickLabels: {
                fontFamily: "Gotham",
                fontWeight: 600,
              },
            }}
          />

          <V.VictoryBar
            cornerRadius={{
              top: 3,
            }}
            data={graphBar}
            style={{
              data: {
                stroke: colors.themeDarkBlue,
                fill: "url(#gradient1)",
                strokeWidth: 3,
                width: 38,
              },
            }}
          />
        </V.VictoryChart>
      </div>
    </Box>
  );
};
